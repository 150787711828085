<template>
  <div>
    <NavTitle class="mb16" :title="showBack ? '返回' : '用户投诉'" :showBack="showBack" @back="handleBack">
      <template #right>
        <div class="flex items-center">
          <el-select class="mr10" v-model="queryType" placeholder="请选择查询类型">
            <el-option
              v-for="item in queryTypes"
              :key="item.type"
              :label="item.text"
              :value="item.type">
            </el-option>
          </el-select>
          <div class="input-row">
            <el-input class="input" placeholder="请输入ID/手机号" clearable v-model="seachWord" @change="handleSearch"></el-input>
            <el-button slot="append" type="primary" icon="el-icon-search"  @click="handleSearch"></el-button>
          </div>
        </div>
      </template>
    </NavTitle>
    <div class="ml20 mr20">
      <el-menu  :default-active="listType" mode="horizontal" active-text-color="#08C8BD"
      @select="menuSelect">
        <el-menu-item :index="item.type" v-for="(item, index) in menuList" :key="index" style="position:relative;">
          {{item.name}}
          <span v-if="item.type == 1 && totalNum && Number(totalNum.dealingNum) != 0" class="msg-count">{{totalNum.dealingNum > 99 ? '99+' : totalNum.dealingNum}}</span>
          <span v-if="item.type == 2 && totalNum && Number(totalNum.overDueNum) != 0" class="msg-count">{{totalNum.overDueNum > 99 ? '99+' : totalNum.overDueNum}}</span>
          <!-- <span v-if="item.type == 3 && Number(totalNum.apprisedNum)" class="msg-count">{{totalNum.apprisedNum}}</span> -->
          <span v-if="item.type == 4 && totalNum && Number(totalNum.completedNum) != 0" class="msg-count">{{totalNum.completedNum > 99 ? '99+' : totalNum.completedNum}}</span>
          <span v-if="item.type == 5 && totalNum && Number(totalNum.revokedNum) != 0" class="msg-count">{{totalNum.revokedNum > 99 ? '99+' : totalNum.revokedNum}}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="panel">
      <el-row :gutter="20" v-if="reportList && reportList.length > 0">
        <el-col :span="12" class="pointer mt16" v-for="(item, index) in reportList" :key="index">
          <div class="report" @click="toDetail(item)">
            <div class="top-row">
              <div class="flex between-center">
                <div class="flex items-center">
                  <LoadImage class="avatar" :src="item.reporterLogo" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
                  <span class="name">{{item.reporterName}}</span>
                </div>
                <div class="flex items-center">
                  <!-- <div class="timeout" v-if="item.state == 6">{{item.stateDesc}}</div> -->
                  <!-- <div class="timeout" v-if="item.state == 2">客服已介入</div> -->
                  <div class="timeout">{{item.stateDesc}}</div>
                  <div class="time">{{item.reportTimeDesc}}</div>
                </div>
              </div>
              <div class="cate-row">
                <span>投诉类型:</span>
                <span>{{item.typeDesc}}</span>
              </div>
            </div>
            <div class="content">
              <div class="post-row">
                <span>被投诉岗位：</span>
                <span>{{item.postName}}</span>
              </div>
              <div class="title-desc">投诉内容说明：</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-empty v-else description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
      <!-- 分页 -->
      <div class="flex justify-center mt24" v-if="reportList.length > 0">
        <el-pagination
          v-if="totalPage"
          layout="prev, pager, next"
          :total="totalPage" :current-page="page.current + 1" :page-size="page.size" @current-change="handlePageChange">
        </el-pagination>
      </div>
    </div>
  </div>
  
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import LoadImage from '@/components/LoadImage'
import { reportedStatics } from '@/api/report.js'
import { reportsApi } from '@/api/admin.js'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
export default {
  name: 'ReportList',
  components: {
    NavTitle,
    Pagination,
    LoadImage
  },
  data() {
    return {
      menuList: [
        {name: '新投诉', type: '1'},
        {name: '超时未处理', type: '2'},
        // {name: '已申请客服介入', type: '3'},
        {name: '处理完成', type: '4'},
        {name: '已撤销', type: '5'},
      ],
      queryTypes: [
        {text: '根据商户信息查询', type: 1},
        {text: '根据用户信息查询', type: 2}
      ],
      reportList: [],
      page: {
        current: 0,
        size: 10
      },
      listType: '1',
      totalNum: null,
      seachWord: '',
      queryType: 1
    }
  },
  computed: {
    totalPage() {
      if(!this.totalNum) return 0
      if(this.listType == 1) return this.totalNum.dealingNum || 0
      if(this.listType == 2) return this.totalNum.overDueNum || 0
      if(this.listType == 4) return this.totalNum.completedNum || 0
      if(this.listType == 5) return this.totalNum.revokedNum || 0
    },
    showBack() {
      const { mid } = this.$route.query
      return mid ? true : false
    }
  },
  created() {
    this.getReportList()
    this.getTotalNum()
  },
  beforeRouteUpdate(to, from, next) {
    this.handleBack()
    next()
  },
  activated() {
    console.log('this.$route.query', this.$route.query)
    const { mid } = this.$route.query
    if(mid) {
      this.seachWord = mid
      // this.listType = '1'
      this.getReportList()
      this.getTotalNum()
    }
  },
  methods: {
    getReportList() {
      const formData = {
        searchKey: this.seachWord.trim(),
        type: Number(this.listType),
        orderType: 'desc',
        startPage: this.page.current,
        pageSize: this.page.size,
        queryType: this.queryType
      }
      console.log('formdata', formData)
      reportsApi(formData).then(res => {
        console.log('投诉列表',res)
        const list = res.data.reportedLists || []
        if(list.length != 0 || this.page.current == 0) {
          list.forEach(item => {
            if(item.reporterLogo?.startsWith('oss')) {
              ossKeyToUrl({key: item.reporterLogo}).then(ossRes => {
                item.reporterLogo = ossRes.url
              })
            }
          })
          this.reportList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.page.current--
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    getTotalNum() {
      console.log('queryType', this.queryType)
      const formData = {
        searchKey: this.seachWord.trim(),
        queryType: this.queryType == 1 ? 0 : 1, // 0 商户 1 用户
      }
      reportedStatics(formData).then(res => {
        console.log('统计数据', res)
        this.totalNum = res.data
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    menuSelect(val) {
      this.listType = val
      this.page.current = 0
      this.getReportList()
    },
    toDetail(item) {
      console.log(item)
      this.$router.push({
        path: '/report/detail',
        query: {
          pid: item.pid,
          rid: item.rid
        }
      })
    },
    handleSearch() {
      this.page.current = 0
      this.getReportList()
      this.getTotalNum()
    },
    handlePageChange(e) {
      console.log(e)
      this.page.current = e - 1
      this.getReportList()
    },
    handleBack() {
      this.seachWord = ''
      this.getReportList()
      this.getTotalNum()
    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 380px;
  display: flex;
  align-items: center;
}
.panel {
  // background-color: #fff;
  border-radius: 8px;
  padding-bottom: 24px;
  margin: 0 20px;
}
.report {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  .top-row {
    padding: 20px 20px 12px;
    border-bottom: 1px solid #EAEAEA;
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 8px;
      overflow: hidden;
    }
    .name {
      font-size: 16px;
      font-weight: bold;
    }
    .time {
      font-size: 14px;
      color: #99A3A3;
    }
    .cate-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
    }
    .timeout {
      padding: 2px 8px;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      background-color: var(--theme-color-red);
      margin-right: 8px;
    }
  }
  .content {
    padding: 12px 20px 20px;
    .post-row {
      font-size: 16px;
      font-weight: bold;
    }
    .title-desc {
      font-size: 14px;
      font-weight: bold;
      margin-top: 4px;
    }
    .desc {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      font-size: 14px;
      color: #505E5C;
      margin-top: 4px;
    }
  }
}

.msg-count {
  position: absolute;
  right: 0;
  top: 20%;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  background-color: #FF5F58;
  color: #fff;
  font-size: 12px;
  border-radius: 16px;
  margin-left: 16px;
  padding: 0 4px;
}
</style>